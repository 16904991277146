<template>
  <div>
    <section class="limited-width-content product-search">
      <CallToActionBase
          variant="product-search"
          :title="productSearch.title"
          :subtitle="productSearch.subtitle" />
    </section>

    <section class="limited-width-content product-categories-cta">
      <h2>
        4x4 Aftermarket Parts <span class="divider">&</span> Overlanding Equipment
      </h2>
      <ShopCategoryListBase
          variant="homepage"
          :items="categories" />
    </section>

    <section
        v-loading="ShowProductsLoader"
        class="product-list highlight">
      <div class="section-inner limited-width-content">
        <h2>Hot Specials! Limited Time Only!</h2>
        <ShopProductListBase
            :items="Products"
            :limit="6" />
      </div>
    </section>

    <section class="limited-width-content newsletter-signup">
      <CallToActionBase
          variant="newsletter-signup"
          :title="newsletterSignup.title"
          :subtitle="newsletterSignup.subtitle" />
    </section>
  </div>
</template>

<script>
import CallToActionBase from "@/Modules/Core/components/CallToAction/CallToActionBase";
import homepageCategories from "@/Modules/Shop/static/homepageCategories.json"
import homeViewMetaMixin from './HomeMetaData';

export default {
  name: "Home",
  components: { CallToActionBase },
  mixins: [homeViewMetaMixin],
  data() {
    return {
      categories: homepageCategories,
      products: [],
      productSearch: {
        title: "Find exciting 4x4 Accessories and Offroad Parts",
        subtitle: "Turn your trips into the adventures that you've always dreamed of!",
      },
      newsletterSignup: {
        title: "Don't miss any deals",
        subtitle: "Sign up to our newsletter for specials and product news"
      }
    }
  },
  computed: {
    ShowProductsLoader() {
      return !(this.Products && this.Products.length > 0);

    },
    Categories() {
      return this.$store.state.Shop.categories
    },
    Products() {
      const result = this.$store.getters["Shop/filteredProducts"]({
        page: 1,
        perPage: 6,
        order: "desc",
        showAt: 'homepage',
      });
      return result.products;
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  padding-top: var(--padding-xxl);
}
</style>
