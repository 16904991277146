import PagesView from "@/Modules/Pages/views/PagesView";
import store from "./store";
import routes from "./routes";

const PagesModule = {
  path: 'pages',
  name: 'Pages',
  enabled: true,
  store: store,
  children: routes,
  component: PagesView
};

export default PagesModule;
