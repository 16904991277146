const homeViewMetaMixin = {
  metaInfo() {
    return {
      title: `${this.$metaData.homepage.title}`,
      meta: [
        {
          name: 'description',
          content: this.$metaData.homepage.description
        },
        {
          property: 'og:type',
          content: "homepage"
        },
        {
          property: 'og:title',
          content: `${this.$metaData.homepage.title}`
        },
        {
          name: 'og:description',
          content: this.$metaData.homepage.description
        },
        {
          name: 'article:publisher',
          content: this.$metaData.homepage.publisher
        },
      ]
    }
  },
}

export default homeViewMetaMixin