<template>
  <div class="density-graph-container">
    <transition name="fade">
      <div
          v-if="visible"
          class="graph-outer">
        <div class="graph-inner">
          <svg
              id="svg-graph">
            <rect
                v-for="(path, index) in pathItems"
                :id="path.id"
                :key="index"
                :x="path.x"
                :y="path.y"
                :width="path.width"
                :height="path.height"
                rx="4"
                class="graph-rectangle"
                :fill="getPathFillColor(index)"
                @click="scrollMeTo(path.itemId)">
              <title>{{ path.value }} AUD</title>
            </rect>
          </svg>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "DensityGraph",
  props: {
    items: {
      type: Array,
      required: true
    },
    selected: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      pathItems: [],
      visible: true,
    }
  },
  watch: {
    items: {
      handler() {
        this.createSVGPathItemsFromAttribute()
      },
      deep: true
    }
  },
  mounted() {
    this.createSVGPathItemsFromAttribute()
  },
  methods: {
    scrollMeTo(id) {
      this.$emit("selectItem",id);
    },
    getPathFillColor(index) {
      if (this.selected === index) {
        return '#4dd98b'
      }
      return 'rgba(0,0,0,0.07)';
    },
    createSVGPathItemsFromAttribute() {

      const attributeMax = Math.max(...this.items.map(item => item.value));

      this.pathItems = this.items.map((item,index) => ({
        x: (index * 12) + index,
        y: 0,
        height: (item.value / attributeMax) * 100,
        width: 10,
        fill: '#FFF',
        value: item.value,
        itemId: item.itemId,
        id:  `svg-headline-graph-${item.Id}`,
        link: `headline-${index + 1}`
      }))

    }
  }
};
</script>

<style lang="scss" scoped>
.density-graph-container {

  label {
    font-size: var(--font-size-m);
    font-weight: 600;
    background: var(--color-special-success);
    color: white;
    border-radius: var(--border-radius-xs);
    margin-left: var(--margin-s);
    padding: var(--padding-xs);
  }

  .graph-outer {
    padding: var(--padding-s);
    position: relative;
    z-index: 1;
    width: 100%;

    .graph-inner {
      width: 100%;
      overflow-x: auto;
      overscroll-behavior-x: contain;
    }


    #svg-graph {
      height: 100%;
      width: 100%;
      transform: scaleY(-1);

      .graph-rectangle {
        transition: var(--main-transition);
        cursor: pointer;

        &:hover {
          fill: #4dd38d4a;
        }
      }
    }
  }
}
</style>