<template>
  <router-link
      :to="item.url"
      class="top-navigation-item">
    {{ item.title }}
    <span
        v-if="item.badge"
        class="badge">
      {{ item.badge }}
    </span>
  </router-link>
</template>

<script>
export default {
  name: 'NavigationItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.top-navigation-item {
  font-family: var(--font-secondary);
  font-size: var(--font-size-l);
  background-color: var(--color-primary-background);
  border-radius: var(--border-radius-s);
  padding: var(--padding-xs) var(--padding-m);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  transition: var(--main-transition);
  letter-spacing: .5px;
  position: relative;
  font-weight: 500;

  &:hover,
  &.router-link-exact-active {
    background-color: var(--color-secondary-background);
    border: 2px solid var(--color-primary-link);
    box-shadow: 0 4px 4px rgba(0,0,0,0.15);
  }

  .badge {
    font-size: var(--font-size-s);
    vertical-align: super;
    font-weight: 500;
    background-color: var(--color-tertiary-background);
    padding: var(--padding-xs) var(--padding-xs);
    border-radius: var(--border-radius-s);
    display: inline-block;
    position: absolute;
    top: -0.5rem;
    right: -1rem;
  }
}

@media (max-width: 475px) {
  .top-navigation-item {
    font-size: var(--font-size-m);
    padding: var(--padding-xs) var(--padding-s);
    border: 0;

    &:hover,
    &.router-link-exact-active {
      border: 0;
    }
  }
}
</style>
