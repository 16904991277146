<template>
  <div class="pages-view">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "PagesView"
};
</script>

<style lang="scss">
@import "PagesView.scss";
</style>