const topMenuItems = [
  {
    title: 'Home',
    description: 'Back to home',
    url: '/home',
    loggedIn: false
  },
  {
    title: 'Explore',
    description: 'Explore our Shop',
    url: '/shop/',
    loggedIn: false
  },
  {
    title: 'Ute Canopies',
    badge: 'New',
    description: 'Explore our Shop',
    url: '/shop/Canopies',
    loggedIn: false
  },
  {
    title: '4x4 Parts',
    description: 'Shop for Aftermarket Vehicle Parts',
    url: '/shop/4x4-Parts',
    loggedIn: false
  },
  {
    title: '4x4 Accessories',
    description: 'Shop for 4x4 Accessories',
    url: '/shop/4x4-Accessories',
    loggedIn: false
  },
  {
    title: 'Interior',
    description: 'Shop for Interior',
    url: '/shop/Interior',
    loggedIn: false
  },
];

export { topMenuItems };
