import axios from "axios";

const state = () => {
  return {
    emailSent: false,
    businessDetails: {
      businessName: "CaneToadEquip Pty Ltd",
      businessShortName: "CanetoadEquip",
      acn: "616441911",
      abn: "46156195587",
      city: "Coopers Plains",
      state: "Queensland",
      postcode: "4156",
      country: "Australia",
      phone: "123-456-789",
      email: "sales@canetoadquip.com.au",
      emailLegal: "legal@canetoadequip.com.au",
      emailPrivacy: "legal@canetoadequip.com.au",
      website: "www.canetoadequip.com.au"
    },
    businessHours: [
      {
        Monday: "9am - 5pm",
        Tuesday: "9am - 5pm",
        Wednesday: "9am - 5pm",
        Thursday: "9am - 5pm",
        Friday: "9am - 5pm",
        Saturday: "by appointment only",
        Sunday: "closed",
      }
    ]
  }
}

const actions = {
  async sendEmail({ commit, rootState }, payload) {
    try {
      const { api } = rootState.Shop
      const response = await axios.post(`${location.protocol}//${api}/api/mail`, payload)

      if (response.data.success) {
        commit('UPDATE_EMAIL_SENT', true)
      } else {
        commit('UPDATE_EMAIL_SENT', false)
      }
    } catch (error) {
      console.error(error)
    }
  }
}

const mutations = {
  UPDATE_EMAIL_SENT(state, payload) {
    state.emailSent = payload
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
};