<template>
  <div class="word-density-container">
    <label @click="visible = !visible">
      Search in Document
    </label>
    <transition name="fade">
      <div
          v-if="visible"
          class="graph-outer">
        <div class="graph-inner">
          <svg
              id="svg-graph">
            <rect
                v-for="(path, index) in pathItems"
                :id="path.id"
                :key="index"
                :x="path.x"
                :y="path.y"
                :width="path.width"
                :height="path.height"
                rx="4"
                class="graph-rectangle"
                :fill="getPathFillColor(index)"
                @click="scrollMeTo(path.link)" />
          </svg>
        </div>
        <el-input
            v-model="searchTerm"
            type="search"
            size="mini"
            placeholder="Keyword Search" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "WordDensity",
  props: {
    wordCount: {
      type: Array,
      required: true,
    },
    glossary: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pathItems: [],
      searchTerm: "",
      visible: false
    }
  },
  watch: {
    searchTerm(val) {
      if(val.length > 3 || val.length === 0 ) {
        this.$emit("updateSearchTerm",val)
      }
    },
    wordCount: {
      handler() {
        this.createSVGPathItemsFromWordCount()
      },
      deep: true
    }
  },
  mounted() {
    this.createSVGPathItemsFromWordCount()
  },
  methods: {
    scrollMeTo(id) {
      this.$emit("onScrollMeTo",id);
    },
    getPathFillColor(index) {
      if (this.glossary[index].inView) {
        return '#4dd98b'
      }
      return 'rgba(0,0,0,0.07)';
    },
    createSVGPathItemsFromWordCount() {

      const maxWordCount = Math.max(...this.wordCount.map(item => item.words));

      this.pathItems = this.wordCount.map((item,index) => ({
        x: (index * 15) + index,
        y: 0,
        height: (item.words / maxWordCount) * 100,
        width: 10,
        fill: '#FFF',
        id:  `svg-headline-graph-${index}`,
        link: `headline-${index + 1}`
      }))

    }
  }
};
</script>

<style lang="scss" scoped>
label {
  font-size: var(--font-size-m);
  font-weight: 600;
  background: var(--color-special-success);
  color: white;
  border-radius: var(--border-radius-xs);
  margin-left: var(--margin-s);
  padding: var(--padding-xs);
}
.graph-outer {
  background: #FFF;
  padding: var(--padding-s);
  border-radius: var(--border-radius-s);
  position: relative;
  z-index: 1;
  box-shadow: var(--box-shadow-product);

  .graph-inner {
    width: 100%;
    overflow-x: auto;
    overscroll-behavior-x: contain;
  }


  #svg-graph {
      height: 100%;
      transform: scaleY(-1);

      .graph-rectangle {
        transition: var(--main-transition);
        cursor: pointer;

        &:hover {
          fill: #4dd38d4a;
        }
      }
    }
}
</style>