const routes = [
  {
    path: '/pages/contact-us',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "contact-us" */ './views/ContactUs')
  },
  {
    path: '/pages/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ './views/PrivacyPolicy')
  },
  {
    path: '/pages/return-policy',
    name: 'return-policy',
    component: () => import(/* webpackChunkName: "return-policy" */ './views/ReturnPolicy')
  },
  {
    path: '/pages/terms-and-conditions',
    name: 'terms-and-conditions',
    component: () => import(/* webpackChunkName: "terms-and-conditions" */ './views/TermsAndConditions')
  },
  {
    path: '/pages/mission-and-vision',
    name: 'mission-and-vision',
    component: () => import(/* webpackChunkName: "mission-and-vision" */ './views/MissionAndVision')
  },
  {
    path: '/pages/warranty-and-returns',
    name: 'warranty-and-returns',
    component: () => import(/* webpackChunkName: "warranty-and-returns" */ './views/WarrantyAndReturns')
  },
]

export default routes;