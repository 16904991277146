<template>
  <div>
    <form
        autocomplete="off"
        @submit.prevent="noop">
      <el-input
          :id="id"
          ref="autocomplete"
          v-model="autocompleteText"
          type="text"
          :class="className"
          :placeholder="placeholder"
          autocomplete="false"
          @focus="onFocus()"
          @blur="onBlur()"
          @change="onChange"
          @keypress="onKeyPress"
          @keyup="onKeyUp" />
    </form>
  </div>
</template>

<script>
const CITIES_TYPE = ["locality", "administrative_area_level_3"];
const REGIONS_TYPE = [
  "locality",
  "sublocality",
  "postal_code",
  "country",
  "administrative_area_level_1",
  "administrative_area_level_2"
];
export default {
  name: "GeoAutocomplete",
  props: {
    id: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Start typing"
    },
    types: {
      type: String,
      default: "address"
    },
    country: {
      type: [String, Array],
      default: () => ["au", "nz"]
    },
    enableGeolocation: {
      type: Boolean,
      default: false
    },
    geolocationOptions: {
      type: Object,
      default: null
    },
    autoComplete: {
      type: String,
      default: "new-password"
    }
  },
  data() {
    return {
      autocomplete: null,
      autocompleteText: "",
      geolocation: {
        geocoder: null,
        loc: null,
        position: null
      }
    };
  },
  watch: {
    autocompleteText: function(newVal, oldVal) {
      this.$emit("inputChange", { newVal, oldVal }, this.id);
    },
    country: function() {
      this.autocomplete.setComponentRestrictions({
        country: this.country === null ? [] : this.country
      });
    }
  },
  mounted: function() {
    const options = {};
    if (this.types) {
      options.types = [this.types];
    }
    if (this.country) {
      options.componentRestrictions = {
        country: this.country
      };
    }
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(this.id),
      options
    );
    this.autocomplete.addListener("place_changed", this.onPlaceChanged);
  },
  methods: {
    noop() {
      return false;
    },
    /**
     * When a place changed
     */
    onPlaceChanged() {
      const place = this.autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        this.$emit("no-results-found", place, this.id);
        return;
      }
      if (place.address_components !== undefined) {
        // return returnData object and PlaceResult object
        this.$emit("placechanged", this.formatResult(place), place, this.id);
        // update autocompleteText then emit change event
        this.autocompleteText = document.getElementById(this.id).value;
        this.onChange();
      }
    },
    /**
     * When the input gets focus
     */
    onFocus() {
      this.biasAutocompleteLocation();
      this.$emit("focus");
    },
    /**
     * When the input loses focus
     */
    onBlur() {
      this.$emit("blur");
    },
    /**
     * When the input got changed
     */
    onChange() {
      this.$emit("change", this.autocompleteText);
    },
    /**
     * When a key gets pressed
     * @param  {Event} event A keypress event
     */
    onKeyPress(event) {
      this.$emit("keypress", event);
    },
    /**
     * When a keyup occurs
     * @param  {Event} event A keyup event
     */
    onKeyUp(event) {
      this.$emit("keyup", event);
    },
    /**
     * Clear the input
     */
    clear() {
      this.autocompleteText = "";
    },
    /**
     * Focus the input
     */
    focus() {
      this.$refs.autocomplete.focus();
    },
    /**
     * Blur the input
     */
    blur() {
      this.$refs.autocomplete.blur();
    },
    /**
     * Update the value of the input
     * @param  {String} value
     */
    update(value) {
      this.autocompleteText = value;
    },
    updateCoordinates(value) {
      if (!value && !(value.lat || value.lng)) return;
      if (!this.geolocation.geocoder)
        this.geolocation.geocoder = new google.maps.Geocoder();
      this.geolocation.geocoder.geocode(
        { location: value },
        (results, status) => {
          if (status === "OK") {
            results = this.filterGeocodeResultTypes(results);
            if (results[0]) {
              this.$emit(
                "placechanged",
                this.formatResult(results[0]),
                results[0],
                this.id
              );
              this.update(results[0].formatted_address);
            } else {
              this.$emit("error", "no result for provided coordinates");
            }
          } else {
            this.$emit("error", "error getting address from coords");
          }
        }
      );
    },
    /**
     * Update location based on navigator geolocation
     */
    geolocate() {
      this.updateGeolocation((geolocation) => {
        this.updateCoordinates(geolocation);
      });
    },
    updateGeolocation(callback = null) {
      if (navigator.geolocation) {
        const options = {};
        if (this.geolocationOptions)
          Object.assign(options, this.geolocationOptions);
        navigator.geolocation.getCurrentPosition(
          position => {
            const geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            this.geolocation.loc = geolocation;
            this.geolocation.position = position;
            if (callback) callback(geolocation, position);
          },
          err => {
            this.$emit("error", "Cannot get Coordinates from navigator", err);
          },
          options
        );
      }
    },
    // Bias the autocomplete object to the user's geographical location,
    // as supplied by the browser's 'navigator.geolocation' object.
    biasAutocompleteLocation() {
      if (this.enableGeolocation) {
        this.updateGeolocation((geolocation, position) => {
          const circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          });
          this.autocomplete.setBounds(circle.getBounds());
        });
      }
    },
    formatResult(place) {
      const ADDRESS_COMPONENTS = {
        subpremise: "short_name",
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        administrative_area_level_1: "long_name",
        administrative_area_level_2: "long_name",
        country: "short_name",
        postal_code: "short_name"
      };

      const returnData = {};
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        if (ADDRESS_COMPONENTS[addressType]) {
          returnData[addressType] = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
        }
      }
      returnData.latitude = place.geometry.location.lat();
      returnData.longitude = place.geometry.location.lng();
      return returnData;
    },
    filterGeocodeResultTypes(results) {
      if (!results || !this.types) return results;
      const output = [];
      let types = [this.types];
      if (types.includes("(cities)")) types = types.concat(CITIES_TYPE);
      if (types.includes("(regions)")) types = types.concat(REGIONS_TYPE);
      for (const r of results) {
        for (const t of r.types) {
          if (types.includes(t)) {
            output.push(r);
            break;
          }
        }
      }
      return output;
    }
  }
};
</script>
