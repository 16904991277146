<template>
  <div class="keyword-search-filter-container">
    <el-input
        v-model="Keyword"
        class="search-input"
        type="search"
        :placeholder="placeholder"
        clearable
        @clear="onClearKeyword"
        @change="onChangeKeyword">
      <i
          slot="prefix"
          class="el-icon-search el-input__icon" />
    </el-input>

    <transition name="fade">
      <template v-if="showStoredSearches && storedKeywords && storedKeywords.length">
        <div class="stored-search-keywords">
          <div class="label-container">
            <label>Your Recent Searches</label>
            <a
                href="#"
                @click.prevent="onClearAll">
              Clear All
            </a>
          </div>
          <ul>
            <li
                v-for="(keyword, index) in storedKeywords"
                :key="index">
              <a
                  href="#"
                  @click.prevent="onSelectKeyword(keyword)">
                {{ keyword }}
              </a>
              <a
                  href="#"
                  @click.prevent="onDeleteKeyword(keyword)"><el-icon class="el-icon-circle-close" /></a>
            </li>
          </ul>
        </div>
      </template>
    </transition>

    <transition name="fade">
      <template v-if="showResultPreview && Products && Products.length">
        <div class="product-preview-list-container">
          <div class="product-list-wrapper">
            <ShopProductListBase
                :items="Products" />
            <div
                class="find-more-tile">
              <button
                  class="primary"
                  @click.prevent="onAllResultsClick">
                See all Search Results
              </button>
            </div>
          </div>
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
export default {
  name: "KeywordSearchFilter",
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    showResultPreview: {
      type: Boolean,
      default: false
    },
    showStoredSearches: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      storedKeywords: null
    }
  },
  computed: {
    Products() {
      if (this.Keyword && this.showResultPreview) {

      const result = this.$store.getters["Shop/filteredProducts"]({
        page: 1,
        perPage: 3,
        order: "desc",
      });
      return result.products;
    }
      return []
    },
    Keyword: {
      get() {
        return this.$store.state.Shop.filters.keywordSearch
      },
      set(value) {
        this.$emit('filterChanges', value)
      }
    }
  },
  mounted() {
   this.readLocalStorageItems()
  },
  methods: {
    onAllResultsClick() {
      this.$router.push({ path: '/shop/', query: { keyword: this.Keyword } });
    },
    readLocalStorageItems() {
      const localStorageSearchKeywords = localStorage.getItem('searchKeywords')
      if (localStorageSearchKeywords) {
        this.storedKeywords = JSON.parse(localStorageSearchKeywords).reverse()
      }
    },
    onClearAll() {
      localStorage.setItem('searchKeywords', JSON.stringify([]))
      this.readLocalStorageItems()
    },
    onDeleteKeyword(payload) {
      const localStorageSearchKeywords = localStorage.getItem('searchKeywords')
      if (localStorageSearchKeywords) {
        const searchKeywords = JSON.parse(localStorageSearchKeywords)
        const index = searchKeywords.indexOf(payload)
        if (index > -1) {
          searchKeywords.splice(index, 1)
        }
        localStorage.setItem('searchKeywords', JSON.stringify(searchKeywords))
        this.readLocalStorageItems()
      }
    },
    onChangeKeyword(payload) {
      const keyword = payload.trim()

      if (keyword.length > 3) {
        let keywords = []

        const localStorageSearchKeywords = localStorage.getItem('searchKeywords')
        if (localStorageSearchKeywords) {
          keywords = JSON.parse(localStorageSearchKeywords)
          if (keywords.length > 0) {
            if (!keywords.includes(keyword)) {
              keywords.push(keyword)
            }
          } else {
            keywords.push(keyword)
          }

          // if more than 5 keywords remove the first one
          if (keywords.length > 5) {
            keywords.shift()
          }

        }
        localStorage.setItem('searchKeywords', JSON.stringify(keywords))
        this.readLocalStorageItems()
        this.$emit('saveKeyword', this.Keyword)
      } else if (keyword.length === 0){
        this.$emit('clearKeyword')
      }

    },
    onSelectKeyword(value){
      this.Keyword = value
      this.$emit('saveKeyword', value)
    },
    onClearKeyword() {
      this.$emit('clearKeyword')
    },
  }
};
</script>

<style lang="scss" scoped>
.keyword-search-filter-container {
  margin: 0 var(--margin-m);

  ::v-deep .search-input {
    input {
      border-radius: var(--border-radius-xxl);
      padding: var(--padding-l);
      text-indent: 30px;
      border-color: transparent;
      box-shadow: var(--box-shadow-shorter);
    }
  }
  .stored-search-keywords {
    background: rgba(255,255,255,0.4);
    padding: var(--padding-l) 0 var(--padding-s) 0;
    border-radius: var(--border-radius-l);
    margin-top: -26px;
    box-shadow: var(--box-shadow-shorter);

    .label-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: var(--margin-l) var(--margin-l) 0 var(--margin-l);
      font-size: var(--font-size-m);


      label {
        display: block;
      }
    }

    ul {
      list-style: none;
      font-size: var(--font-size-l);
      padding: var(--padding-s) 0;
      margin: var(--margin-m) 0 0 0;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--padding-xs) var(--padding-l);
        transition: var(--main-transition);

        &:hover {
          background: #Fff;
        }
      }
    }
  }

  .product-preview-list-container {
    padding: var(--padding-m) 0;

    ::v-deep .product-list-wrapper {

      .find-more-tile {
        margin-left: var(--margin-m);
      }

      .default-product-list {
        z-index: 3;
        gap: 10px;
        flex-wrap: nowrap;
        left: -20px;
        position: relative;
        padding: var(--padding-m);

      }
      .default-product-list-item-container {

        .product-footer {
          display: none;
        }


        .product-container__outer,
        .product-container__inner {
          .description-container {
            .product-title {
              h1 {
                font-size: var(--font-size-m);
              }
            }
          }
          .product-category {
            display: none;
          }
          .image-container {
            min-width: 100px;
            max-width: 100px;
            width: 100px;
            height: auto;

            img {

              width: 100px;
              height: 100px;
            }
          }
        }
      }
    }
  }
}
</style>