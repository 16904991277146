<template>
  <div class="pagination-container">
    <div
        class="block"
        :class="blockClass">
      <el-pagination
          background
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          :pager-count="5"
          :layout="Layout"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CorePagination",
  props: {
    pageSizes: {
      type: Array,
      default: () => [10, 20, 50, 100, 150 ]
    },
    pageSize: {
      type: Number,
      required: true
    },
    layout: {
      type: String,
      default: "total, sizes, pager, prev, next, jumper"
    },
    total: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      default: 1
    },
    blockClass: {
      type: String,
      default: ""
    }
  },
  computed: {
    Layout() {
      let layout = this.layout
      if (this.total / this.pageSize < 1) {
        const layoutArray = this.layout.split(",")
        const filterArray = ["prev", "next", "jumper"]

        layout = layoutArray.filter(item => !filterArray.includes(item.trim()))
        layout = layout.join(",")
      }
      return layout
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("perPageChange", val)
    },
    handleCurrentChange(val) {
      this.$emit("currentPageChange", val)
    }
  }
}
</script>

<style lang="scss" scoped>
.block {

  &.bright {
    margin-top: var(--margin-m);
    background: #FFF;
    padding: var(--padding-m);
    border-radius: var(--border-radius-s);
    box-shadow: 0 1px 6px #eaeaea;
  }
}
</style>