<template>
  <div
      id="app"
      :class="{ push: ShowDrawer }">
    <div class="content-wrapper">
      <main :class="{ opaque: ShowDrawer}">
        <el-container>
          <el-header class="header">
            <router-link to="/home">
              <img
                  class="logo"
                  alt="CaneToad Equipment Logo"
                  src="@/assets/img/canetoad_logo_small.png">
            </router-link>

            <div class="navigation-wrapper">
              <CoreNavigationBase
                  :items="topMenuItems"
                  variant="top" />
            </div>
          </el-header>


          <div
              v-if="1===3"
              class="breadcrumb-wrapper">
            <div class="breadcrumb-container-inner limited-width-content">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/home' }">
                  homepage
                </el-breadcrumb-item>
                <el-breadcrumb-item>promotion management</el-breadcrumb-item>
                <el-breadcrumb-item>promotion list</el-breadcrumb-item>
                <el-breadcrumb-item>promotion detail</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>

          <router-view v-slot="{ Component, route }">
            <transition name="fade">
              <component
                  :is="Component"
                  :key="route.path" />
            </transition>
          </router-view>
        </el-container>
      </main>

      <transition name="fade">
        <aside v-if="ShowDrawer">
          <CoreDrawer
              title="Your Shopping Cart"
              @closeDrawer="handleCloseDrawer">
            <template #content>
              <ShoppingCartBase variant="list-items" />
            </template>
          </coredrawer>
        </aside>
      </transition>
    </div>

    <CoreFooter />
    <ShoppingCartBase variant="floating" />
  </div>
</template>

<script>
import { topMenuItems } from './App';

export default {
  name: 'App',
  data() {
    return {
      topMenuItems: topMenuItems
    };
  },
  computed: {
    ShowDrawer() {
      return !!this.$store.state.Shop.showCart;
    }
  },
  async created() {
    await this.$store.dispatch("Shop/updateIsLoadingAllData", true)
    await this.$store.dispatch("Shop/getProtocol");
    await this.$store.dispatch("Shop/fetchProducts")
    await this.$store.dispatch("Shop/fetchCategories")
    await this.$store.dispatch("Shop/fetchProductImages")
    await this.$store.dispatch("Shop/fetchProductCategoryMapping")
    await this.$store.dispatch("Shop/updateIsLoadingAllData", false)
  },
  methods: {
    handleCloseDrawer() {
      if (this.$store.state.Shop.showCart) {
        this.$store.dispatch("Shop/updateShowCart", false)
      }
    }
  }
};
</script>

<style lang="scss">

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform .3s;
}
.slide-enter,
.slide-leave-active {
  transform: translateY(-100%);
}
.slide-down-enter {
  transform: translateY(-1000px)
}
.slide-down-enter-active,
.slide-down-leave-active {
  transition: transform .3s;
}
.slide-down-enter,
.slide-down-leave-active {
  transform: translateY(1000px);
}

.list-enter-active,
.list-leave-active {
  transition: all .6s;
}
.list-enter,
.list-leave-active {
  transform: translateY(-350px);
  opacity: 0;
}

#app {
  transition: var(--main-transition);

  &.push {
    margin-left: -350px;
  }

  .content-wrapper {
    display: flex;
    justify-content: flex-start;
    position: relative;
    z-index: 2;

    main {
      flex: 3;
      transition: opacity 0.2s;
      &.opaque {
        opacity: .5;
      }
    }

    aside {
      min-width: 350px;
      max-width: 350px;
      flex: 1;

      .drawer {
        background: white;
      }

      @media (max-width: 475px) {
        min-width: auto;
        max-width: initial;
      }


    }
  }
}




</style>
