<template>
  <div
      class="category-advertising-call-to-action-container"
      :style="backgroundImageStyle">
    <div class="content-container">
      <h1 v-if="title">
        {{ title }}
      </h1>
      <h2 v-if="subtitle">
        {{ subtitle }}
      </h2>
      <div class="cta-button-container">
        <router-link
            type="button"
            :to="`/shop/${category}`"
            class="primary cta-button">
          Check it out now
        </router-link>
      </div>
    </div>
    <div class="shade-divider hidden-xs-only">
      <BackgroundShade fill="#301221" />
    </div>
    <div class="image-container" />
  </div>
</template>

<script>
import BackgroundShade from "../components/BackgroundShade";

export default {
  name: "CategoryAdvertisingCAllToAction",
  components: {
    BackgroundShade
  },
  props: {
    variant: {
      type: String,
      default: "default"
    },
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    category: {
      type: String,
      default: null
    },
    image: {
      type: String,
      default: null
    }
  },
  computed: {
    backgroundImageStyle() {
      if (this.image) {
        return null
        // return `background-image: url(${this.image})`;
      }
      return null
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./CategoryAdvertisingCallToAction.scss";
</style>