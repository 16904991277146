<template>
  <div class="navigation-base-container">
    <component
        :is="renderComponent"
        v-bind="$props" />
  </div>
</template>

<script>
import DefaultNavigation from './variants/DefaultNavigation';
import TopNavigation from './variants/TopNavigation/TopNavigation';
import { camelCase, startCase } from 'lodash';

export default {
  name: 'NavigationBase',
  components: {
    DefaultNavigation,
    TopNavigation
  },
  props: {
    variant: {
      type: String,
      default: 'default'
    },
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    renderComponent() {
      if (this.variant === 'default') {
        return 'DefaultNavigation';
      }
      return startCase(camelCase(`${this.variant}Navigation`)).replace(/ /g, '');
    }
  }
};
</script>

<style scoped></style>
