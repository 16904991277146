<template>
  <div class="product-search-call-to-action-container">
    <div class="content-container">
      <h1 v-if="title">
        {{ title }}
      </h1>
      <h2 v-if="subtitle">
        {{ subtitle }}
      </h2>
      <div class="search-container">
        <!--        <el-autocomplete-->
        <!--            v-model="keyword"-->
        <!--            class="search-input"-->
        <!--            popper-class="my-autocomplete"-->
        <!--            :fetch-suggestions="querySearch"-->
        <!--            placeholder="Search products ..."-->
        <!--            @select="handleSelect">-->
        <!--          <i-->
        <!--              slot="prefix"-->
        <!--              class="el-icon-search el-input__icon"-->
        <!--              @click="handleIconClick" />-->
        <!--          <template slot-scope="{ item }">-->
        <!--            <div class="value">-->
        <!--              {{ item.value }}-->
        <!--            </div>-->
        <!--          </template>-->
        <!--        </el-autocomplete>-->
        <KeywordSearchFilter
            :show-stored-searches="false"
            :show-result-preview="true"
            placeholder="Vehicle, Part, or Keyword"
            @filterChanges="updateFilters"
            @saveKeyword="onSaveKeyword"
            @clearKeyword="onClearKeyword" />
      </div>
    </div>
    <div class="shade-divider hidden-xs-only">
      <BackgroundShade fill="#F9D3BD" />
    </div>
    <div class="image-container" />
  </div>
</template>

<script>
import BackgroundShade from "@/Modules/Core/components/CallToAction/components/BackgroundShade";
import KeywordSearchFilter from "@/Modules/Core/components/Filter/variants/KeywordSearchFilter";
export default {
  name: 'ProductSearchCallToAction',
  components: {
    BackgroundShade,
    KeywordSearchFilter
  },
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      keyword: '',
    };
  },
  methods: {
    updateFilters(val) {
      this.$store.dispatch("Shop/updateFilters", { type: 'keywordSearch', values: val });
    },
    onSaveKeyword(keyword) {
      this.$router.push({ query: { keyword: keyword }})
    },
    onClearKeyword() {
      this.$router.push({ query: { }})
    },
    querySearch(queryString, cb) {
      const links = this.links;
      const results = queryString
        ? links.filter(this.createFilter(queryString))
        : links;
      // call callback function to return suggestion objects
      cb(results);
    },
    createFilter(queryString) {
      return (link) => {
        return (
          link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      return item
    },
    handleIconClick(ev) {
      return ev
    }
  }
};
</script>

<style lang="scss" scoped>
@import "ProductSearchCallToAction";
</style>
