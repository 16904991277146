<template>
  <svg
      width="93"
      height="450"
      viewBox="0 0 93 450"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_206_93)">
      <path
          d="M31.9999 157.5L11.9999 -1.00001C-61.5001 -11.3333 -209.3 -29.9 -212.5 -21.5C-216.5 -11 -190.5 418 -188.5 427.5C-186.5 437 -77.5001 469 -68.5001 471C-61.3001 472.6 34.1666 436.333 80.9999 418L64.9999 270.5L31.9999 157.5Z"
          :fill="fill" />
    </g>
    <defs>
      <clipPath id="clip0_206_93">
        <rect
            width="93"
            height="450"
            fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "BackgroundShade",
  props: {
    fill: {
      type: String,
      default: "#000"
    }
  }
};
</script>

<style scoped>

</style>