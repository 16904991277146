<template>
  <div class="auto-glossary-container">
    <div class="word-density-container">
      <WordDensity
          v-if="wordCount.length && showWordDensity"
          :word-count="wordCount"
          :glossary="glossary"
          @onScrollMeTo="scrollMeTo"
          @updateSearchTerm="handleUpdateSearchTerm" />
    </div>
    <ol class="glossary">
      <li
          v-for="(item, index) in glossary"
          :key="`item-${index}`"
          :class="{ highlight: item.inView }">
        <a
            @click="scrollMeTo(item.id)">{{ item.title }}
        </a>
      </li>
    </ol>
  </div>
</template>

<script>
import WordDensity from "./components/WordDensity";
export default {
  name: "AutoGlossary",
  components: {
    WordDensity,
  },
  props: {
    showWordDensity: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      glossary: [],
      wordCount: [],
    }
  },
  mounted() {
    const subHeadlineElements = document.querySelectorAll("article h2")

    this.glossary = Array.from(subHeadlineElements).map(element => (
      {
        id: element.id,
        inView: false,
        title: element.innerText }))

    this.countWordsPerElement(document.querySelectorAll('.article-content'))
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleUpdateSearchTerm(keyword) {
      // get the main element from dom
      // find keyword in the mainElement and wrap it in a span tag


      const elements = document.querySelectorAll('.article-content')
      this.wordCount = Array.from(elements).map(element => {

        // count all words in the element that match the keyword
        const wordMatchCount = element.innerText.split(/\s+/).filter(word => word.toLowerCase().includes(keyword.toLowerCase())).length
        return {
          id: element.id,
          words: wordMatchCount,
        }
      })

      // const mainElement = document.querySelector('main')
      //
      // const keywordSpan = mainElement.innerText.match(new RegExp(keyword, 'gi'))
      // // if keyword is found, wrap it in a span tag
      // if (keywordSpan) {
      //   keywordSpan.forEach(span => {
      //     const spanElement = document.createElement('span')
      //     // add class to spanElement
      //     spanElement.classList.add('search-highlight')
      //     spanElement.innerText = span
      //     mainElement.innerHTML = mainElement.innerHTML.replace(span, spanElement.outerHTML)
      //   })
      // } else {
      //   // if keyword is not found, remove all the span tags
      //   const spanElements = document.querySelectorAll('span.highlight')
      //   spanElements.forEach(span => {
      //     span.remove()
      //   })
      // }

    },
    handleScroll() {
      window.onscroll = () => {
        this.getItemStyles()
      }
    },
    getItemStyles() {

      const elementInViewport = (item) => {
        const element = document.getElementById(item);
        const bounding = element.getBoundingClientRect();

        return bounding.top >= 0 && bounding.left >= 0 &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight);
      }

      this.glossary.forEach((item,index) => {
        this.glossary[index].inView = !!elementInViewport(item.id);
      })

    },
    scrollMeTo(refName) {
      const element = document.querySelector(`#${refName}`);
      const top = element.offsetTop;
      window.scrollTo({top: top, behavior: 'smooth'});
    },
    countWordsPerElement(elements) {

      this.wordCount = Array.from(elements).map(element => {
        // get id of parent element
        const parent = element.parentElement;
        // get element by tag from parent
        const elementByTag = parent.querySelector('h2');
        // get id of element by tag
        const elementId = elementByTag.id;

        const words = element.innerText.split(/\s+/).length;
        return {
          id: elementId,
          words: words
        }
      })
    },

  }
};
</script>

<style lang="scss" scoped>
@import "AutoGlossary";
</style>